.programs{
    display: flex;
    margin-top: 250px;
}
.p-left{
    display: flex;
    flex: 1 1; 
    margin-left: 2rem;
}
.header{
    color: white;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;

}

.data{
    color: white;
    font-size: 1rem;
    padding: 5px;
    text-transform: uppercase;
    
    
}
.data .program{
    padding-left: 2rem;
}
.day{
    padding-left: 3rem;
}

.programs-blur{
    width: 22rem;
    height: 30rem;
    right: 0;
    bottom: 0;
}
.p-right{
    
    flex: 1 1; 
    flex-direction: column;
}
.other-ervices{
    margin-left: 100px;
    flex-direction: column;
    align-items: center;
    width: 60%;
    display: flex;
    border-radius: 8%;
    padding: 10px;
    background: #454242;
    filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
}
.other-ervices>:nth-child(1){
    margin-top: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: white;
}
.service-d{
    list-style-type:decimal;
}
.service-d> li{
    padding: 5px;
    color: white;
}

@media screen and (max-width: 768px) {
    .programs{
        margin-top: 10px;
        display: block;
    }
    .p-left{
        margin-left: 30px;
    }
    
    .header{
        color: white;
        font-weight: bold;
        font-size: 1rem;
        text-transform: uppercase;
    
    }
  
    
    .data{
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        padding: 0 2px 2px 2px;
        text-transform: uppercase;
    }
       
    .p-right{
        margin-top: 20px;
    }
}