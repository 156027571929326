.footer{
    height: 30vh;
    align-items: center;
    display: flex;
    flex-direction: column;
}
footer{
    align-items: center;
}
.span{
    margin-top: 3rem;
    color: white;
    font-size: 1.5rem;
    
}
.ul{
    display: flex;
}
.li{
    margin: 0 2rem 0 2rem;
    color: white;
}

@media screen and (max-width: 768px) {
    .span {
        color: #fff;
        font-size: 1rem;
        margin-top: 3rem;
    }
    .footer{
        height: 25vh;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}