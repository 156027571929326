.home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    margin-bottom: 100px;
  }

  
  /* slider */
  .home .swiper{
      overflow: visible!important;
  }
  
  
  .home img {
   
    filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
   
  }

  .swiper {
    width: 87%;
    height: 117%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .home{
      align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 5px;
    margin-left: 11px;
    margin-right: 11px;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }
    
  }