.n-wrapper {
    background: #ec0b0b;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    top: 0;
    gap: 1rem;
  }
  .logo{
    width: 7rem;
    height: rem;
    margin-left: 2rem;
    border-radius: 2em;
}

.n-left {
    flex: 1;
    align-items: center;
    justify-content: left;
    display: flex;
    gap: 2rem;
  }
  ul{
    list-style: none;
}

.n-list {
    flex: 10;
  }
.n-list > ul {
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
  }
  
  .n-list > ul :hover {
    color: var(--orange);
    cursor: pointer;
  }
  .n-list > ul> li {
    color: white;
  }
  .n-right{
    margin-right: 2rem;
    margin-top: 0.5rem;
  }
  

  @media screen and (max-width: 768px) {
    .n-wrapper {
      height: 10vh;
    }
    .n-left{
      display: none;
    }
 
    audio {
      margin-top: 3px;
      width: 200px;
      height: 50px;
  }
  .n-right {
    margin-left: 8rem;
    margin-top: 0;
    width: 20vh;
  }
  }