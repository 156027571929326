.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80hv;
    margin-top: 4rem;
}
.contact-blur{
    width: 22rem;
    height: 30rem;
    
}
.c-right{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    flex: 1;
}
.right-t{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-bottom: 5px;
}
.c-right>form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3rem;
    outline: none;
    border: 2px solid var(--red);
    border-radius: 8px;
    font-size: 16px;
}
textarea{
    height: 4rem!important;
}
.c-blur1{
    top: 1rem;
    left: 8rem;
}

.head-quaters{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    color: white;
}
.head-quaters>:nth-child(1){
    font-weight: bold;
    font-size: 1.5rem;
}

.contacts{
    color: white;
    margin-bottom: rem;
}

.first{
    font-weight: bold;
}
.text{
    margin-bottom: 0.5rem;
}

.awesome{
    margin-top: 50px;
    margin-left: 100px;
}

@media screen and (max-width: 768px) {
    .w-left{
        display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-bottom: 5px;
    }
    .contact-form{
        padding: 0;
        margin-top: 0;
        flex-direction: column;
        gap: 1rem;
        height: 40rem;
    }
    .c-right .user{
        width: 16rem;
    }
    .blur{
        left: 0;
    width: 20rem;
    height: 16rem;
        
    }

}   